import React, { StrictMode, useEffect } from "react";
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";
import { createRoot } from "react-dom/client";
import axios, { InternalAxiosRequestConfig } from "axios";
import { Provider } from "react-redux";
import ReactGA from "react-ga4";
import * as Sentry from "@sentry/react";
import { getCookieConsentValue } from "react-cookie-consent";
import { store } from "./app/redux/store";
import "./app/assets/sass/style.scss";
import AppRoutes from "./app/routing/AppRoutes";
import { setupAxios } from "./app/modules/auth/core/setupAxios";
import { setupHighcharts } from "./app/helpers/highchartsHelpers";
import { setupFontAwesome } from "./app/helpers/fontAwesomeHelpers";
import { getUserPreferencesAction } from "./app/redux/userInfoReducer";

if (import.meta.env.PROD) {
    Sentry.init({
        dsn: "https://04ae99c80731c7e8708ad79fc98a3037@o4504921286967296.ingest.sentry.io/4505719851450368",
        environment: import.meta.env.VITE_ENV,
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
            Sentry.replayIntegration(),
        ],
        normalizeDepth: 10,
        tracesSampleRate: 1,
        profilesSampleRate: 0.2,
        tracePropagationTargets: [
            /^https:\/\/api-retrieve\.hav-sentry\.com\//,
            /^https:\/\/beta-retrieve\.hav-sentry\.com\//,
        ],
        replaysSessionSampleRate: 0.2,
        replaysOnErrorSampleRate: 1.0,
    });
}

// Initialize Google Analytics if tracking ID has been set
if (import.meta.env.VITE_GA_TRACKING_ID && getCookieConsentValue() === "true") {
    ReactGA.initialize(import.meta.env.VITE_GA_TRACKING_ID);
}

setupAxios(axios);
setupFontAwesome();
setupHighcharts();

axios.interceptors.request.use(
    async function onRequest(config: InternalAxiosRequestConfig) {
        if (!(config.url?.includes("auth") || config.url?.includes("user-details") || config.url?.includes("logout"))) {
            await store.dispatch(getUserPreferencesAction());
        }
        return config;
    },
    function onRequestError(error) {
        return Promise.reject(error);
    },
);

const container = document.getElementById("root");
if (container) {
    if (!import.meta.env.PROD && import.meta.env.VITE_DISABLE_STRICT_MODE === "false") {
        createRoot(container).render(
            <StrictMode>
                <Provider store={store}>
                    <AppRoutes />
                </Provider>
            </StrictMode>,
        );
    } else {
        createRoot(container).render(
            <Provider store={store}>
                <AppRoutes />
            </Provider>,
        );
    }
}
