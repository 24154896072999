import { FC } from "react";
import { useAppSelector } from "../redux/hooks";
import { selectUserTheme } from "../redux/selectors";

const ErrorBoundaryFallback: FC = () => {
    const themeMode = useAppSelector(selectUserTheme);
    const systemMode = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
    const actualTheme = themeMode === "system" ? systemMode : themeMode;
    return (
        <div className="d-flex flex-column flex-column-fluid auth-bg">
            <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                <div className="w-md-500px card mx-auto">
                    <div className="card-body p-10 p-lg-15">
                        <div className="text-center pb-5">
                            <img
                                className="w-75"
                                alt="Logo"
                                src={
                                    actualTheme === "light"
                                        ? "/media/hav-sentry/logo-horizontal-positive.svg"
                                        : "/media/hav-sentry/logo-horizontal-negative.svg"
                                }
                            />
                        </div>
                        <div className="mt-5 text-center">
                            <p>We are sorry for the inconvenience.</p>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                    window.location.reload();
                                }}
                            >
                                Reload
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ErrorBoundaryFallback;
